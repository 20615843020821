body {
  background-color: #e6e3e3;
  direction: rtl;
}

.login-container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
}
.login {
  background-color: white;
  border: 1px solid #ddd;
  padding: 2rem;
  width: 350px;
  border-radius: 10px;
}
.login .form-header {
  text-align: center;
  margin-bottom: 2rem;
}
.login .form-group {
  margin-bottom: 1rem;
}
.login .form-group input {
  background: #fafafa;
  border: 1px solid #eeeeee;
  padding: 12px;
  width: 100%;
  border-radius: 10px;
}
.login .form-group h4 {
  margin-bottom: 1vh;
  font-size: 1em;
  font-weight: lighter;
}
.login .form-group h4.message {
  text-align: center;
  font-size: 1.05em;
  color: red;
}
.login button {
  background: #51a1ff;
  border: 1px solid #ddd;
  color: #ffffff;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  font-size: 1em;
  font-weight: bolder;
  cursor: pointer;
}

.login button:disabled,
.login button[disabled] {
  background-color: #496e97;
  cursor: wait;
}

.top-section-container {
  position: sticky;
  top: 0;
  z-index: 100;
}

.top-bar {
  text-align: center;
  padding: 1.2vh;
  background-color: #51a1ff;
  color: white;
  font-size: 1.5em;
  font-weight: bolder;
  font-size: 3vh;
}
.display-by {
  background-color: white;
  padding: 1.6vh 1.6vh 1.6vh;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.display-by .title {
  font-size: 3vh;
  font-weight: 700;
  color: #2e2e2e;
}

.display-by .display-by-options {
  display: flex;

  padding-top: 0.5vh;
  width: 70%;
  table-layout: fixed;
  border-collapse: collapse;
}

.display-by button {
  color: white;
  background-color: #2e2e2e;
  padding: 0.8em;
  line-height: 0.1rem;
  border: 0;
  margin-left: 1vh;
  font-size: 1.1rem;
  border-radius: 9px;
  cursor: pointer;
  width: 100%;
  outline: none;
}
.display-by button.selected {
  background: #51a1ff;
}
.display-by .container .count {
  background: #51a1ff;
  color: white;
  width: 2.1rem;
  height: 2.1rem;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.display-by .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
}
.fade-in {
  position: relative;
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.3s;
  animation-name: fadeIn;
  animation-duration: 0.3s;
}

.float-new-btn {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 10px;
  right: 10px;
  font-size: 20px;
  line-height: 25px;
  background: #51a1ff;
  color: white;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 3px #999;
}
.float-new-btn.pointer {
  cursor: pointer;
}

.float-new-btn.selection-container {
  position: fixed;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
  border-radius: 5px;
  background-color: white;
  z-index: 1;
  width: 90px;

  height: 35.7px;
  bottom: 50px;
}
.float-new-btn.selection-container .inner-container {
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: space-between;
}

.float-new-btn.selection-container .option {
  color: #51a1ff;
  width: 100%;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
}
.float-new-btn.selection-container .option:hover {
  background-color: #e6e6e6;
}

.float-new-btn.selection-container .inner-container .devider {
  border-top: 1px solid rgba(179, 161, 161, 0.534);
}

.loading {
  text-align: center;
}

.articles {
  margin: 10px;
  overflow-y: auto;
}

.articles .no-items {
  text-align: center;
  font-size: 1.5rem;
}

.articles .article {
  background-color: white;
  padding: 1vh;
  border-radius: 10px;
  margin-top: 12px;
}
.articles .article .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.articles .article .container .actions {
  min-width: 23%;
  display: grid;
  margin-right: 0.5vh;
}
@media only screen and (min-width: 750px) {
  .articles .article .container .actions {
    min-width: 23%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin-right: 0.5vh;
  }
}

.fa-twitter {
  color: #1da1f2;
}
.fa-facebook {
  color: #4267b2;
}
.fa-whatsapp {
  color: #4ac959;
  color: green;
}
.fa-bell {
  color: #e44949;
}
.fa-trash {
  color: black;
}

.articles .article .container button i {
  font-size: 20px;
}
.articles .article .container button {
  border: 2px solid #51a1ff;
  /*
  color: white;
  background-color: #51a1ff;
  */
  background-color: white;
  padding: 0.25em;
  line-height: 0.1rem;
  margin-left: 1vh;
  font-size: 1.1rem;
  border-radius: 9px;
  cursor: pointer;
  width: 100%;
  outline: none;
}
.articles .article .container button:disabled {
  opacity: 0.5;
  cursor: default;
  /*
  background-color: #e2e2e2a4;
  */
}
@media only screen and (max-width: 750px) {
  .article .actions > button:not(:first-child) {
    margin-top: 10px;
  }
}
.articles .article .container .sec-btn {
  margin-top: 1vh;
}

.articles .article .container .text {
  overflow-wrap: anywhere;
  font-size: 1.1em;
}
.articles .article .container .text .status {
  margin-top: 0.15em;
  color: #51a1ff;
}
.popup-container {
  z-index: 99999;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.62);
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.popup {
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  margin: auto;
  width: 90%;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.popup .close {
  position: absolute;
  margin-right: 6px;
  font-size: 1.5em;
  color: #51a1ff;
  cursor: pointer;
  display: inline-block;
}

.checkbox-container {
  margin: auto;
}

.short-fb-checkbox-container {
  margin: 10px;
}
.short-fb-label {
  color: #51a1ff;
}

.popup .content {
  padding: 15px;
}
.minimized {
  display: none;
}
.popup .content .top-container {
  display: flex;
  justify-content: space-between;
}
.popup .content .top-container h3 {
  text-align: left;
  width: 100%;
  text-align: center;
}
.popup .content .top-container i {
  color: #51a1ff;
  cursor: pointer;
}

.popup .content .platform {
  padding: 10px;
  /*border: 2px solid #51a1ff;*/
  margin-top: 10px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
  border-radius: 5px;
}
.popup .content .platform .push label {
  color: #51a1ff;
}
.popup .content .platform .push input[type="search"] {
  width: calc(100% - 10px);
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
  margin-top: 5px;
  width: 100%;
}
.popup .content .platform .push > .section:not(:first-child) {
  margin-top: 10px;
}

.popup .content .platform .push .image-preview img {
  max-width: 35%;
}

.popup .content .platform .push .image-preview {
  text-align: center;
  margin-top: 5px;
}
.popup .content .platform .push .section label {
  font-size: 1.1em;
}
.popup .content .header {
  font-size: 0.8em;
  margin-bottom: 10px;
  text-align: center;
}

.popup .content .body textarea {
  width: 100%;
  border: 2px solid #51a1ff;
  font-size: 1.2em;
  border-radius: 5px;
  resize: vertical;
  padding: 5px;
}

.popup .content .body .actions {
  margin-top: 10px;
  text-align: center;
}

.popup .content .body .actions button {
  color: white;
  background-color: #51a1ff;
  padding: 0.8em;
  line-height: 0.1rem;
  border: 0;
  margin-left: 1vh;
  font-size: 1.1rem;
  border-radius: 9px;
  cursor: pointer;
  outline: none;
  width: 100%;
}
.popup .content .body .actions i {
  color: white;
  line-height: 0.1rem;
  font-size: 1.1rem;
}
.popup .content .body .actions button[disabled] {
  background-color: #496e97;
  cursor: wait;
}

.popup .content .body textarea:focus {
  outline: none !important;
  box-shadow: 0 0 10px #719ece;
}

input[type="checkbox"]:focus {
  outline: 1px solid rgba(0, 0, 0, 0.2);
}

input[type="checkbox"] {
  margin-left: 5px;
  background-color: #ddd;
  border-radius: 2px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
  top: 5px;
}
.checkbox-label-container {
  display: flex;
  align-items: center;
}

.media-options {
  display: flex;
}
.media-options > :not(:last-child) {
  margin-left: 12px;
}

input[type="checkbox"]:checked {
  background-color: #51a1ff;
  background: #51a1ff
    url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==")
    5px 5px no-repeat;
}

.popup .content .body .post-to {
  display: grid;
  justify-content: center;
}
.popup .content .body .post-to h3 {
  margin: auto;
}

.popup .content .body .post-to h4 {
  margin-bottom: 1px;
}

.popup .content .body .delete-title {
  margin-bottom: 3px;
  text-align: center;
  color: red;
  margin: 12px;
}
.popup .content .body .text-content {
  background: antiquewhite;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.1em;
}
.popup .content .body .schedule {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 10px;
}

.checkbox-label {
  font-size: 1.1em;
}
@media only screen and (min-width: 700px) {
  .display-by .display-by-options {
    width: 35%;
  }
  .articles {
    width: 70%;
    margin: auto;
  }
  .popup {
    width: 40%;
  }
}

.logout {
  position: fixed;
  bottom: 1px;
  left: 5px;
  font-size: 17px;
  cursor: pointer;
  text-decoration: underline;
}
